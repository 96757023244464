import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import PriceDisplay from './PriceDisplay';
import ExtendedHoursDisplay from './ExtendedHoursDisplay';
import { useTheme } from '@mui/material/styles';
import { formatPrice } from '../utils/numberUtils';

const StockPriceContainer = ({ stockData, todayHoliday }) => {
    const [currentPrice, setCurrentPrice] = useState(stockData.price);
    const theme = useTheme();

    // Handle price updates from the PriceDisplay component
    const handlePriceUpdate = (newPrice) => {
        setCurrentPrice(newPrice);
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PriceDisplay
                    initialData={stockData}
                    exchange={stockData.exchange}
                    onPriceUpdate={handlePriceUpdate}
                />

                {/* Price change and percentage */}
                <Typography
                    variant="h6"
                    color={
                        stockData.change > 0
                            ? theme.palette.positive.main
                            : stockData.change < 0
                                ? theme.palette.negative.main
                                : theme.palette.lightGray.main
                    }
                >
                    {formatPrice(stockData.change)} ({stockData.changesPercentage}%)
                </Typography>
            </Box>

            <ExtendedHoursDisplay
                symbol={stockData.symbol}
                exchange={stockData.exchange}
                regularPrice={currentPrice}
                currency={stockData.currency}
                todayHoliday={todayHoliday}
            />
        </Box>
    );
};

export default StockPriceContainer;